<template>
  <div class="landing-bg-login">
    <b-container>
      <div class="login-form">
        <div class="main-div">
          <div class="panel">
            <div class="text-center">
              <img src="../assets/logo2.png" style="width: 200px; margin: 0 auto 30px" />
            </div>
            <h2 class="signin-label text-blue">Select customer</h2>
            <div class="tenant-list">
              <div
                class="tenant-item"
                v-for="tenant in tenants"
                :key="'tenant_' + tenant.Tenant"
                @click="loginIntoTenant(tenant.Tenant)"
              >
                {{ tenant.Name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader :fullPage="true"></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/v2/Loader';

export default {
  name: 'SelectTenant',
  components: {
    Loader,
  },
  computed: {
    teamContext() {
      return this.$store.getters['app/getTeamContext'];
    },
    tenants() {
      return this.$store.getters['app/getTenantList'];
    },
    accessToken() {
      return this.$store.getters['app/getAccessToken'];
    },
  },
  mounted() {
    if (!this.tenants.length) {
      this.$router.push('/login');
    }
  },
  methods: {
    loginIntoTenant(tenant) {
      this.$store.dispatch('authentication/loginIntoTenant', {
        azureToken: this.accessToken,
        tenant,
      });
    },
  },
};
</script>

<style>
body {
  background-color: #efefef;
}
.landing-bg-login {
  background: #444444;
  background-image: url('../assets/bg/4.jpg');
  background-size: cover;
  height: 100vh;
}
.tenant-list {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
}
.tenant-item {
  border: 1px solid #262261;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  word-break: break-all;
}
.tenant-item:hover {
  color: #fff;
  background-color: #262261;
}
</style>
