<template>
  <div>
    <b-navbar class="intop-navbar">
      <div class="navbar-container">
        <div class="logo-container">
          <a href="/" class="logo-wrapper" style="text-align: center">
            <img src="../assets/logo2.png" />
          </a>
        </div>
        <b-navbar-nav style="margin-left: 15px" class="search-box-navbar">
          <b-nav-form @submit.prevent>
            <div class="me-5">
              <ResourceSearchForm />
            </div>
          </b-nav-form>
        </b-navbar-nav>
        <b-navbar-nav class="paginator-wrapper">
          <div class="right-menu-nav">
            <SupportMenu />
            <FavoritesMenu />
            <UserMenu />
          </div>
        </b-navbar-nav>
      </div>
    </b-navbar>
    <ProgressBar />
  </div>
</template>

<script>
import { debounce } from '@/helpers';
import UserMenu from './UserMenu';
import ResourceSearchForm from '@/components/resourceSearch/ResourceSearchForm';
import ProgressBar from '@/components/Progressbar';
import SupportMenu from '@/components/SupportMenu';
import FavoritesMenu from '@/components/favorites/FavoritesMenu';

export default {
  name: 'Navbar',
  components: {
    UserMenu,
    ResourceSearchForm,
    ProgressBar,
    FavoritesMenu,
    SupportMenu,
  },
  data() {
    return {};
  },
  methods: {
    debouncer: debounce(function (searchString) {
      this.$store.dispatch('filterParams/searchString', searchString);
    }, 800),
    pushRoute() {
      this.$router.push('/manage/context');
    },
  },
  computed: {
    searchString: {
      set(searchString) {
        this.debouncer(searchString);
      },
      get() {
        return this.$store.state.filterParams.searchString;
      },
    },
  },
};
</script>

<style scoped>
.intop-navbar {
  background-color: #ffffff;
  padding-top: 17px;
  padding-bottom: 18px;
}

.intop-navbar .right-menu-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intop-navbar .navbar-container {
  display: flex;
  width: 100%;
}

.navbar.fixed-top {
  background-color: #f8f9fe;
}

.logo-container {
  width: 295px;
  padding-left: 10px;
}
.logo-container img {
  width: 165px;
}

.navbar-brand {
  width: 240px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: black;
}

.paginator-wrapper {
  display: flex;
}

.nav-actions-wrapper {
  align-items: center;
}

.admin-action-button {
  height: 38px;
  margin-top: 8px;
  margin-right: 10px;
  background-color: #fff;
  padding: 0px;
  font-weight: 500;
  padding: 0px 20px;
}

.search-box-navbar {
  display: flex;
  flex-grow: 1;
  margin-right: 10px;
  margin-left: 23px;
}

.search-box-navbar form,
.search-box-navbar form input div,
.search-box-navbar form .form-inline .form-control {
  display: block;
  width: 100%;
}

.search-box-navbar form {
  margin-top: 7px;
}

.search-box-navbar .form-inline,
.search-box-navbar .form-inline input.intop-resource-search {
  width: 100% !important;
}

.user-menu-nav a.nav-link {
  padding-right: 0px;
  margin-top: 5px;
  height: 40px;
}

.support-menu-nav a.nav-link {
  padding-left: 0px;
  margin-top: 5px;
  height: 40px;
}
</style>
