<template>
  <div class="landing-bg-login">
    <b-container>
      <transition name="fade">
        <div class="login-form" v-if="!loginConfigIsLoading">
          <div class="main-div">
            <div class="panel">
              <div class="text-center">
                <img src="../assets/logo2.png" style="width: 200px; margin: 0 auto 30px" />
              </div>
              <h2 class="signin-label text-blue">Sign in</h2>
              <form id="login" @submit.prevent="handleSubmit" v-if="showDefaultLogin">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control intop-login-field"
                    id="username"
                    placeholder="Username"
                    v-model="username"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control intop-login-field"
                    id="password"
                    placeholder="Password"
                    v-model="password"
                  />
                </div>
                <Button
                  class="w-100 intop-login-button"
                  label="SIGN IN"
                  :showLoader="true"
                  :isLoading="isLoading && !showFullpageLoader"
                />
              </form>
              <div class="login-method-divider" v-if="loginType.length === 2">Or</div>
              <button
                type="button"
                class="btn w-100 ms-0 intop-login-button intop-microsoft-login-btn"
                v-if="showMicrosoftLogin"
                @click.prevent="loginViaAzure"
              >
                <img class="img-fluid" src="@/assets/microsoft.png" alt="" width="25" />
                SIGN IN WITH MICROSOFT
              </button>
            </div>
          </div>
        </div>
      </transition>
      <Loader :fullPage="true"></Loader>
    </b-container>
  </div>
</template>

<script>
import { azureSSO } from '@/services/azureSSO.service';
import Button from '@/components/v2/Button';
import Loader from '@/components/v2/Loader';

export default {
  name: 'Login',
  components: {
    Button,
    Loader,
  },
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      msalInstance: null,
    };
  },
  watch: {
    username() {
      if (this.loginAttemptFailed) {
        this.$store.commit('authentication/toggleLoginAttempt', false);
      }
    },
    password() {
      if (this.loginAttemptFailed) {
        this.$store.commit('authentication/toggleLoginAttempt', false);
      }
    },
    microsoftConfig(newVal) {
      if (!this.msalInstance) {
        this.createMsalInstance(this.microsoftConfig.clientId, this.microsoftConfig.tenantId);

        this.loginViaAzureSilent();
      }
    },
    loginAttemptFailed(isFailed) {
      if (!isFailed) {
        this.$store.dispatch('alert/clear');
      }
    },
  },
  computed: {
    loginAttemptFailed() {
      return this.$store.state.authentication.loginFailed;
    },
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
    loginConfig() {
      return this.$store.getters['loginConfig/getConfig'];
    },
    loginType() {
      if (this.loginConfig && this.loginConfig.loginType && this.loginConfig.loginType.length > 0) {
        return this.loginConfig.loginType;
      }
      return ['default'];
    },
    showDefaultLogin() {
      if (this.loginType && this.loginType.includes('default')) {
        return true;
      }
      return false;
    },
    showMicrosoftLogin() {
      if (this.loginType && this.loginType.includes('microsoft')) {
        return true;
      }
      return false;
    },
    microsoftConfig() {
      if (this.loginType && this.loginType.includes('microsoft') && this.loginConfig.microsoftConfig) {
        return this.loginConfig.microsoftConfig;
      }
      return null;
    },
    isLoading() {
      return this.$store.getters['loader/getLoadingStatus'];
    },
    loginConfigIsLoading() {
      return this.$store.getters['loginConfig/getLoadingStatus'];
    },
    showFullpageLoader() {
      return this.$store.getters['loader/getFullpageLoaderStatus'];
    },
  },
  mounted() {
    if (this.microsoftConfig) {
      this.createMsalInstance(this.microsoftConfig.clientId, this.microsoftConfig.tenantId);
    }
  },
  created() {
    // reset login status
    // this.$store.dispatch("authentication/logout");
    this.loginViaTeams();
  },
  methods: {
    handleSubmit(e) {
      this.$store.commit('authentication/toggleLoginAttempt', false);
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch('authentication/login', { username, password });
      } else {
        dispatch('alert/error', `The username or password is incorrect`);
        this.$store.commit('authentication/toggleLoginAttempt', true);
      }
    },
    async loginViaAzure() {
      try {
        this.$store.commit('authentication/toggleLoginAttempt', false);
        const loginRequest = {
          scopes: ['user.read'], // optional Array<string>
          prompt: 'select_account',
        };
        this.$store.dispatch('loader/setLoadingStatus', true);
        this.$store.dispatch('loader/setFullpageLoaderStatus', true);
        // this.msalInstance.loginRedirect(loginRequest);
        const loginResponse = await this.msalInstance.loginPopup(loginRequest);
        this.$store.dispatch('authentication/loginViaAzure', loginResponse.accessToken);
      } catch (err) {
        this.$store.dispatch('loader/setLoadingStatus', false);
        this.$store.dispatch('loader/setFullpageLoaderStatus', false);
        const errorMessage = err.message || `Login failed!`;

        const ignoreableErrors = ['user_cancelled', 'consent_required'].filter((errorType) =>
          errorMessage.includes(errorType)
        );

        if (ignoreableErrors.length == 0) {
          this.$store.dispatch('alert/error', errorMessage || `Login failed!`);
        }
      }
    },
    createMsalInstance(clientId, tenantId) {
      this.msalInstance = azureSSO.msalInstance(clientId, tenantId);
    },
    async loginViaAzureSilent() {
      this.$store.commit('authentication/toggleLoginAttempt', false);
      const accounts = this.msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        this.msalInstance.setActiveAccount(accounts[0]);
        try {
          this.$store.dispatch('loader/setLoadingStatus', true);
          this.$store.dispatch('loader/setFullpageLoaderStatus', true);
          var silentRequest = {
            scopes: ['user.Read'],
            account: accounts[0],
            forceRefresh: true,
          };
          const token = await this.msalInstance.acquireTokenSilent(silentRequest);
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('loader/setFullpageLoaderStatus', false);
          if (token) {
            this.$store.dispatch('authentication/loginViaAzure', token.accessToken);
          }
        } catch (error) {
          this.$store.dispatch('loader/setLoadingStatus', false);
          this.$store.dispatch('loader/setFullpageLoaderStatus', false);
        }
      }
    },
    loginViaTeams() {
      microsoftTeams.getContext((context) => {
        if (context) {
          this.$store.dispatch('loader/setLoadingStatus', true);
          this.$store.dispatch('loader/setFullpageLoaderStatus', true);
          microsoftTeams.authentication.getAuthToken({
            successCallback: (token) => {
              this.$store.dispatch('loader/setLoadingStatus', false);
              this.$store.dispatch('loader/setFullpageLoaderStatus', false);
              this.$store.dispatch('authentication/loginViaTeams', token);
            },
            failureCallback: (error) => {
              this.$store.dispatch('loader/setLoadingStatus', false);
              this.$store.dispatch('loader/setFullpageLoaderStatus', false);
            },
          });
        }
      });
    },
  },
};
</script>

<style>
body {
  background-color: #efefef;
}
.landing-bg-login {
  background: #444444;
  background-image: url('../assets/bg/4.jpg');
  background-size: cover;
  height: 100vh;
}
.form-heading {
  color: #fff;
  font-size: 23px;
}
.panel h2 {
  color: #444444;
  font-size: 18px;
  margin: 0 0 8px 0;
}
h2.signin-label {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.panel p {
  color: #777777;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 24px;
}
.login-form .form-control {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  margin-bottom: 1rem;
}
.main-div {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 2px;
  margin: 10px auto 30px;
  max-width: 400px;
  padding: 30px 50px 60px;
}

.login-form .form-group {
  margin-bottom: 10px;
}
.login-form {
  padding-top: 150px;
}

.login-form .btn.btn-primary {
  height: 50px;
  line-height: 50px;
  padding: 0;
}
.error-message {
  color: #da4b4b;
  border: 1px solid #f1dcdc;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 4px;
}
</style>

<style scoped>
.login-method-divider {
  text-align: center;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem !important;
}
.intop-microsoft-login-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.75rem;
  border-color: #262261;
  color: #262261;
  background: #fff;
}
.intop-microsoft-login-btn:hover {
  color: #fff;
  background: #262261;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
